/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ContentInteractionStateEventDetailsCaseFromJSON, ContentInteractionStateEventDetailsCaseToJSON, } from './ContentInteractionStateEventDetailsCase';
import { ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedFromJSON, ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedToJSON, } from './ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmitted';
import { ContentInteractionStateEventDetailsMarkWritingQuestionAsSubmittedFromJSON, ContentInteractionStateEventDetailsMarkWritingQuestionAsSubmittedToJSON, } from './ContentInteractionStateEventDetailsMarkWritingQuestionAsSubmitted';
import { ContentInteractionStateEventDetailsUpdateObjectiveAnswerFromJSON, ContentInteractionStateEventDetailsUpdateObjectiveAnswerToJSON, } from './ContentInteractionStateEventDetailsUpdateObjectiveAnswer';
import { ContentInteractionStateEventDetailsUpdateWritingAnswerFromJSON, ContentInteractionStateEventDetailsUpdateWritingAnswerToJSON, } from './ContentInteractionStateEventDetailsUpdateWritingAnswer';
import { UpdateElapsedTimeMsFromJSON, UpdateElapsedTimeMsToJSON, } from './UpdateElapsedTimeMs';
/**
 * Check if a given object implements the ContentInteractionStateEventDetails interface.
 */
export function instanceOfContentInteractionStateEventDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function ContentInteractionStateEventDetailsFromJSON(json) {
    return ContentInteractionStateEventDetailsFromJSONTyped(json, false);
}
export function ContentInteractionStateEventDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'markSpeakingQuestionAsSubmitted': !exists(json, 'markSpeakingQuestionAsSubmitted') ? undefined : ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedFromJSON(json['markSpeakingQuestionAsSubmitted']),
        'markWritingQuestionAsSubmitted': !exists(json, 'markWritingQuestionAsSubmitted') ? undefined : ContentInteractionStateEventDetailsMarkWritingQuestionAsSubmittedFromJSON(json['markWritingQuestionAsSubmitted']),
        'oneOfCase': ContentInteractionStateEventDetailsCaseFromJSON(json['oneOfCase']),
        'updateElapsedTimeMs': !exists(json, 'updateElapsedTimeMs') ? undefined : UpdateElapsedTimeMsFromJSON(json['updateElapsedTimeMs']),
        'updateObjectiveAnswer': !exists(json, 'updateObjectiveAnswer') ? undefined : ContentInteractionStateEventDetailsUpdateObjectiveAnswerFromJSON(json['updateObjectiveAnswer']),
        'updateWritingAnswer': !exists(json, 'updateWritingAnswer') ? undefined : ContentInteractionStateEventDetailsUpdateWritingAnswerFromJSON(json['updateWritingAnswer']),
    };
}
export function ContentInteractionStateEventDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'markSpeakingQuestionAsSubmitted': ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedToJSON(value.markSpeakingQuestionAsSubmitted),
        'markWritingQuestionAsSubmitted': ContentInteractionStateEventDetailsMarkWritingQuestionAsSubmittedToJSON(value.markWritingQuestionAsSubmitted),
        'oneOfCase': ContentInteractionStateEventDetailsCaseToJSON(value.oneOfCase),
        'updateElapsedTimeMs': UpdateElapsedTimeMsToJSON(value.updateElapsedTimeMs),
        'updateObjectiveAnswer': ContentInteractionStateEventDetailsUpdateObjectiveAnswerToJSON(value.updateObjectiveAnswer),
        'updateWritingAnswer': ContentInteractionStateEventDetailsUpdateWritingAnswerToJSON(value.updateWritingAnswer),
    };
}
