/* tslint:disable */
/* eslint-disable */
/**
 * 셀이 어떤 학습 활동을 통해 사용자에게 제공되었는지를 나타낸다.
 *
 * - **DIAGNOSIS**: 진단고사
 * - **COURSE_LEARNING**: 추천학습
 * - **SELF_LEARNING**: 선택학습
 * - **MY_NOTE**: 복습
 * - **TODAYS_LEARNING**: 오늘의 학습
 * @export
 */
export const LearningCellProvidedContext = {
    DIAGNOSIS: 'DIAGNOSIS',
    COURSE_LEARNING: 'COURSE_LEARNING',
    SELF_LEARNING: 'SELF_LEARNING',
    MY_NOTE: 'MY_NOTE',
    TODAYS_LEARNING: 'TODAYS_LEARNING'
};
export function LearningCellProvidedContextFromJSON(json) {
    return LearningCellProvidedContextFromJSONTyped(json, false);
}
export function LearningCellProvidedContextFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCellProvidedContextToJSON(value) {
    return value;
}
