/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { QuestionStateV2DetailsCaseFromJSON, QuestionStateV2DetailsCaseToJSON, } from './QuestionStateV2DetailsCase';
import { QuestionStateV2ObjectiveFromJSON, QuestionStateV2ObjectiveToJSON, } from './QuestionStateV2Objective';
import { QuestionStateV2SpeakingFromJSON, QuestionStateV2SpeakingToJSON, } from './QuestionStateV2Speaking';
import { QuestionStateV2WritingFromJSON, QuestionStateV2WritingToJSON, } from './QuestionStateV2Writing';
/**
 * Check if a given object implements the QuestionStateV2Details interface.
 */
export function instanceOfQuestionStateV2Details(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function QuestionStateV2DetailsFromJSON(json) {
    return QuestionStateV2DetailsFromJSONTyped(json, false);
}
export function QuestionStateV2DetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'objective': !exists(json, 'objective') ? undefined : QuestionStateV2ObjectiveFromJSON(json['objective']),
        'oneOfCase': QuestionStateV2DetailsCaseFromJSON(json['oneOfCase']),
        'speaking': !exists(json, 'speaking') ? undefined : QuestionStateV2SpeakingFromJSON(json['speaking']),
        'writing': !exists(json, 'writing') ? undefined : QuestionStateV2WritingFromJSON(json['writing']),
    };
}
export function QuestionStateV2DetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'objective': QuestionStateV2ObjectiveToJSON(value.objective),
        'oneOfCase': QuestionStateV2DetailsCaseToJSON(value.oneOfCase),
        'speaking': QuestionStateV2SpeakingToJSON(value.speaking),
        'writing': QuestionStateV2WritingToJSON(value.writing),
    };
}
