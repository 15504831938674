/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElementInteractionStateV2DetailsFromJSON, ElementInteractionStateV2DetailsToJSON, } from './ElementInteractionStateV2Details';
/**
 * Check if a given object implements the ElementInteractionStateV2 interface.
 */
export function instanceOfElementInteractionStateV2(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function ElementInteractionStateV2FromJSON(json) {
    return ElementInteractionStateV2FromJSONTyped(json, false);
}
export function ElementInteractionStateV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentElementId': !exists(json, 'contentElementId') ? undefined : json['contentElementId'],
        'details': ElementInteractionStateV2DetailsFromJSON(json['details']),
        'id': json['id'],
    };
}
export function ElementInteractionStateV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentElementId': value.contentElementId,
        'details': ElementInteractionStateV2DetailsToJSON(value.details),
        'id': value.id,
    };
}
