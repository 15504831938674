import React from 'react';
import TagManager from 'react-gtm-module';
import {useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {mobileService} from '@app/api/app-bridge/mobile-service';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

/**
 * learning domain 변화를 감지하여 query removal을 진행하는 컴포넌트
 */
const LearningDomainSubscriber = ({children}: {children?: React.ReactNode}) => {
  /**
   * learning domain의 변화만 감지하기 위해 사용하는 ref
   */
  const initialRef = React.useRef(true);
  const learningDomain = useAtomValue(learningDomainAtom);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        learningDomain: learningDomain.displayName,
      },
    });
    if (isAppBridgeAvailable()) {
      mobileService.setUserProperties({learningDomain: learningDomain.displayName});
    }
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    /**
     * learningDomainId에 종속되는 상태를 모두 제거하기 위함.
     */
    queryClient.resetQueries();
  }, [learningDomain.displayName, queryClient]);

  return <>{children}</>;
};

export default React.memo(LearningDomainSubscriber);
