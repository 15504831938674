/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { QuestionStateV2AnswerStateFromJSON, QuestionStateV2AnswerStateToJSON, } from './QuestionStateV2AnswerState';
import { QuestionStateV2DetailsFromJSON, QuestionStateV2DetailsToJSON, } from './QuestionStateV2Details';
/**
 * Check if a given object implements the QuestionStateV2 interface.
 */
export function instanceOfQuestionStateV2(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "questionId" in value;
    return isInstance;
}
export function QuestionStateV2FromJSON(json) {
    return QuestionStateV2FromJSONTyped(json, false);
}
export function QuestionStateV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answerState': !exists(json, 'answerState') ? undefined : QuestionStateV2AnswerStateFromJSON(json['answerState']),
        'details': QuestionStateV2DetailsFromJSON(json['details']),
        'questionId': json['questionId'],
    };
}
export function QuestionStateV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answerState': QuestionStateV2AnswerStateToJSON(value.answerState),
        'details': QuestionStateV2DetailsToJSON(value.details),
        'questionId': value.questionId,
    };
}
