import '@app/polyfills';
import '@riiid/design-system/index.css';
import '@app/styles/index.css';
import '@app/global.css';

import {AppProps} from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {useTranslation} from 'react-i18next';
import {useIsomorphicLayoutEffect, useMount} from 'react-use';
import {URL_IMG_FAVICON_BLACK_32, URL_IMG_FAVICON_WHITE_32} from '@riiid/design-system/images/santa';
import {setTag as setSentryTag} from '@sentry/nextjs';
import {Analytics} from '@vercel/analytics/react';
import {formatISO} from 'date-fns';
import {JoinProviders} from '@santa-web/service-common';
import {CssBaseline, useColorTheme} from '@santa-web/service-ui';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import MetaPixel from '@app/api/MetaPixel';
import {CssMobileSafeAreaVariable} from '@app/components';
import Boot from '@app/components/core/Boot';
import LearningDomainSubscriber from '@app/components/core/LearningDomainSubscriber';
import TokenSubscriber from '@app/components/core/TokenSubscriber';
import FontOverrider from '@app/components/FontOverrider';
import MobileAppUpdateGuard from '@app/components/guards/MobileAppUpdateGuard';
import RedirectionGuard from '@app/components/guards/RedirectGuard';
import LogScreenViewEvent from '@app/components/LogScreenViewEvent';
import SentryErrorBoundary from '@app/components/SentryErrorBoundary';
import SpoqaHanSans from '@app/components/SpoqaHanSans';
import ThrowUnhandledRejection from '@app/components/ThrowUnhandledRejection';
import WebviewStyles from '@app/components/WebviewStyles';
import config from '@app/config';
import DialogProvider from '@app/contexts/providers/DialogProvider';
import QueryClientProvider from '@app/contexts/providers/QueryClientProvider';
import ToastProvider from '@app/contexts/providers/ToastProvider';
import {ColorScheme} from '@app/features/preferences';
import useNaverLogAnalyticsCommonScript from '@app/hooks/naver-log-analytics-script/useNaverLogAnalyticsCommonScript';
import {useClickBreadcrumb} from '@app/hooks/trace/useClickBreadcrumb';
import useMetaTagData from '@app/hooks/useMetaData';
import {useRootRouteChange} from '@app/hooks/useRootRouteChange';
import {useSubscribeDeepLinkEffect} from '@app/hooks/useSubscribeDeepLinkEffect';
import {useToggleNavigationGestureEffect} from '@app/hooks/useToggleNavigationGestureEffect';
import {registerShowLocaleSettingEvent, registerThemeToggleEvent} from '@app/misc/dev-events';
import {ReactQueryDevTools} from '@app/misc/ReactQueryDevTools';
import initMsw from '@app/mocks';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const NoSSR = dynamic(() => import('@app/components/EmptyContainer'), {
  ssr: false,
});

if (config.env !== 'production' && typeof window !== 'undefined') {
  registerThemeToggleEvent();
  registerShowLocaleSettingEvent();
}

let stopMsw: (() => void) | undefined;

initMsw({isEnabled: false}).then(stop => {
  stopMsw = stop;
});

function MyApp({Component, pageProps}: AppProps) {
  const isWebview = isAppBridgeAvailable();
  const isDarkTheme = useColorTheme() === 'dark';
  const {i18n, t} = useTranslation();
  const {openGraphImage, openGraphLocale} = useMetaTagData();

  const handleLanguageChanged = async (language: string) => {
    if (isAppBridgeAvailable()) {
      mobileService.setUserProperties({language});
    }
    TagManager.dataLayer({
      dataLayer: {
        language,
      },
    });
  };

  const {shouldBlockRenderingChildren} = useRootRouteChange();

  useClickBreadcrumb();

  useNaverLogAnalyticsCommonScript();

  useSubscribeDeepLinkEffect();

  useToggleNavigationGestureEffect();

  useMount(() => {
    TagManager.initialize({
      gtmId: config.gtmId,
      preview: config.gtmEnv,
      auth: config.gtmAuth,
    });
    MetaPixel.initialize();

    console.log(`${config.commitHash.substring(0, 6)}. Built at ${formatISO(config.buildTimestamp)}`);

    // Deal with the entrance page
    // handleRouteChangeStart(router.pathname);

    if (isWebview) {
      mobileService.getAppInfo().then(({version}) => {
        if (!version) return;
        const {major, minor, patch} = version;
        setSentryTag('app.version', `${major}.${minor}.${patch}`);
      });
    }
    handleLanguageChanged(i18n.language);

    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  });

  useIsomorphicLayoutEffect(() => {
    ColorScheme.setToRootElement(ColorScheme.getFromStorage());
  }, []);

  useEffect(() => {
    return () => {
      // MSW 업데이트 후 hotload시 msw가 중복실행되는 문제 해결
      if (stopMsw) {
        stopMsw();
      }
    };
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <meta property="og:title" content={t('meta_title')} />
        <meta property="og:description" content={t('meta_description')} />
        <meta property="og:url" content="https://ai.aitutorsanta.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={openGraphImage} />
        <meta property="og:locale" content={openGraphLocale} />
        <meta name="description" content={t('meta_description')} />
        <title>{t('meta_title')}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/SpoqaHanSans-Thin.subset.woff" as="font" type="font/woff" crossOrigin="" />
        <link rel="preload" href="/fonts/SpoqaHanSans-Light.subset.woff" as="font" type="font/woff" crossOrigin="" />
        <link rel="preload" href="/fonts/SpoqaHanSans-Regular.subset.woff" as="font" type="font/woff" crossOrigin="" />
        <link rel="preload" href="/fonts/SpoqaHanSans-Bold.subset.woff" as="font" type="font/woff" crossOrigin="" />
        {isDarkTheme ? (
          <>
            <link rel="shortcut icon" href="/favicon-white.ico" />
            <link rel="apple-touch-icon-precomposed" href={URL_IMG_FAVICON_WHITE_32} />
          </>
        ) : (
          <>
            <link rel="shortcut icon" href="/favicon-black.ico" />
            <link rel="apple-touch-icon-precomposed" href={URL_IMG_FAVICON_BLACK_32} />
          </>
        )}
      </Head>
      <CssBaseline />
      <CssMobileSafeAreaVariable />
      <SpoqaHanSans />
      <FontOverrider />
      {isWebview && <WebviewStyles />}
      <ToastProvider>
        <SentryErrorBoundary>
          <ThrowUnhandledRejection />
          <NoSSR>
            <Boot>
              <JoinProviders
                providers={
                  <>
                    <TokenSubscriber />
                    <LearningDomainSubscriber />
                    <LogScreenViewEvent />
                    <DialogProvider />
                    {isAppBridgeAvailable() && <MobileAppUpdateGuard />}
                    <RedirectionGuard />
                  </>
                }>
                {!shouldBlockRenderingChildren && (
                  <>
                    <ReactQueryDevTools />
                    <Component {...pageProps} />
                    {process.env.VERCEL_ENV === 'production' && process.env.NOW_ENV === 'production' && <Analytics />}
                  </>
                )}
              </JoinProviders>
            </Boot>
          </NoSSR>
        </SentryErrorBoundary>
      </ToastProvider>
    </>
  );
}

const App = (props: AppProps) => {
  return (
    <QueryClientProvider>
      <MyApp {...props} />
    </QueryClientProvider>
  );
};

export default App;
