import { ContentInteractionStateWithContentV2FromJSON, ContentInteractionStateWithContentV2ToJSON, } from './ContentInteractionStateWithContentV2';
/**
 * Check if a given object implements the CreateContentInteractionStatesResponseV2 interface.
 */
export function instanceOfCreateContentInteractionStatesResponseV2(value) {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    return isInstance;
}
export function CreateContentInteractionStatesResponseV2FromJSON(json) {
    return CreateContentInteractionStatesResponseV2FromJSONTyped(json, false);
}
export function CreateContentInteractionStatesResponseV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'created': (json['created'].map(ContentInteractionStateWithContentV2FromJSON)),
    };
}
export function CreateContentInteractionStatesResponseV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'created': (value.created.map(ContentInteractionStateWithContentV2ToJSON)),
    };
}
