/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CompleteAllContentInteractionStatesResponseV2FromJSON, ContentInteractionStateEventToJSON, ContentInteractionStateWithContentV2FromJSON, CreateContentInteractionStatesResponseV2FromJSON, ListContentInteractionStatesResponseV2FromJSON, SummarizeContentInteractionStatesResponseFromJSON, } from '../models';
/**
 *
 */
export class ContentInteractionStateV2Api extends runtime.BaseAPI {
    /**
     * 대상 `LearningCell`의 완료 처리되지 않은 `ContentInteractionState`를 모두 완료 처리한다.
     */
    async completeAllContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling completeAllContentInteractionStates.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/learning-cells/{learningCellId}/content-interaction-states/complete`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CompleteAllContentInteractionStatesResponseV2FromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 완료 처리되지 않은 `ContentInteractionState`를 모두 완료 처리한다.
     */
    async completeAllContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.completeAllContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 완료 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.completedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 완료처리 되면 `updateContentInteractionState` 요청으로 수정이 불가능해진다.
     */
    async completeContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling completeContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/content-interaction-states/{contentInteractionStateId}/complete`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentV2FromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 완료 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.completedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 완료처리 되면 `updateContentInteractionState` 요청으로 수정이 불가능해진다.
     */
    async completeContentInteractionState(requestParameters, initOverrides) {
        const response = await this.completeContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `LearningCell`에 새로운 `ContentInteractionState`들을 생성해 배정한다.
     */
    async createContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling createContentInteractionStates.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/learning-cells/{learningCellId}/content-interaction-states`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateContentInteractionStatesResponseV2FromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `LearningCell`에 새로운 `ContentInteractionState`들을 생성해 배정한다.
     */
    async createContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.createContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 조회한다.
     */
    async getContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling getContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/content-interaction-states/{contentInteractionStateId}`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentV2FromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 조회한다.
     */
    async getContentInteractionState(requestParameters, initOverrides) {
        const response = await this.getContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`들을 조회한다.\\ 응답으로 오는 `ContentInteractionState`는 ID의 오름차순으로 정렬된다.
     */
    async listContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling listContentInteractionStates.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listContentInteractionStates.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.isCompletedCondition !== undefined) {
            queryParameters['isCompletedCondition'] = requestParameters.isCompletedCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/learning-cells/{learningCellId}/content-interaction-states`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListContentInteractionStatesResponseV2FromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`들을 조회한다.\\ 응답으로 오는 `ContentInteractionState`는 ID의 오름차순으로 정렬된다.
     */
    async listContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.listContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `ContentInteractionState`에 대한 학습 이벤트를 추가한다.
     */
    async putContentInteractionStateEventRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling putContentInteractionStateEvent.');
        }
        if (requestParameters.contentInteractionStateEvent === null || requestParameters.contentInteractionStateEvent === undefined) {
            throw new runtime.RequiredError('contentInteractionStateEvent', 'Required parameter requestParameters.contentInteractionStateEvent was null or undefined when calling putContentInteractionStateEvent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/content-interaction-states/{contentInteractionStateId}/events`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentInteractionStateEventToJSON(requestParameters.contentInteractionStateEvent),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentV2FromJSON(jsonValue));
    }
    /**
     * 대상 `ContentInteractionState`에 대한 학습 이벤트를 추가한다.
     */
    async putContentInteractionStateEvent(requestParameters, initOverrides) {
        const response = await this.putContentInteractionStateEventRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 시작 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.startedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 시작처리 되어야만 `putContentInteractionStateEvent`  요청으로 수정이 가능하다.
     */
    async startContentInteractionStateRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling startContentInteractionState.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/content-interaction-states/{contentInteractionStateId}/start`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentInteractionStateWithContentV2FromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `ContentInteractionState`를 시작 처리한다.\\ 시작 처리가 되면 `ContentInteractionState.startedAtServer`이 서버 시각 기준으로 매겨진다.\\ `ContentInteractionState`가 시작처리 되어야만 `putContentInteractionStateEvent`  요청으로 수정이 가능하다.
     */
    async startContentInteractionState(requestParameters, initOverrides) {
        const response = await this.startContentInteractionStateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`에 대한 요약 정보를 조회
     */
    async summarizeContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling summarizeContentInteractionStates.');
        }
        const queryParameters = {};
        if (requestParameters.isCompletedCondition !== undefined) {
            queryParameters['isCompletedCondition'] = requestParameters.isCompletedCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/learning-cells/{learningCellId}/content-interaction-states/summarize`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SummarizeContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `LearningCell`의 `ContentInteractionState`에 대한 요약 정보를 조회
     */
    async summarizeContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.summarizeContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
