/* tslint:disable */
/* eslint-disable */
/**
 * - **UPDATE_ELAPSED_TIME_MS**: 대상 `ContentInteractionState`의 학습 시간을 수정하는 이벤트.
 * - **UPDATE_OBJECTIVE_ANSWER**: 대상 `ContentInteractionState`의 객관식 문제에 대한 답안을 업데이트하는 이벤트.
 * - **MARK_SPEAKING_QUESTION_AS_SUBMITTED**: 대상 `ContentInteractionState`의 말하기 문제를 답변 완료로 업데이트하는 이벤트.
 * - **UPDATE_WRITING_ANSWER**: 대상 `ContentInteractionState`의 쓰기 문제에 대한 답안을 업데이트하는 이벤트.
 * - **MARK_WRITING_QUESTION_AS_SUBMITTED**: 대상 `ContentInteractionState`의 쓰기 문제를 답변 완료로 업데이트하는 이벤트.
 * 해당 이벤트 적용 이후에는 `UPDATE_WRITING_ANSWER` 이벤트를 통해 답안을 업데이트할 수 없다.
 * @export
 */
export const ContentInteractionStateEventDetailsCase = {
    UPDATE_ELAPSED_TIME_MS: 'UPDATE_ELAPSED_TIME_MS',
    UPDATE_OBJECTIVE_ANSWER: 'UPDATE_OBJECTIVE_ANSWER',
    MARK_SPEAKING_QUESTION_AS_SUBMITTED: 'MARK_SPEAKING_QUESTION_AS_SUBMITTED',
    UPDATE_WRITING_ANSWER: 'UPDATE_WRITING_ANSWER',
    MARK_WRITING_QUESTION_AS_SUBMITTED: 'MARK_WRITING_QUESTION_AS_SUBMITTED'
};
export function ContentInteractionStateEventDetailsCaseFromJSON(json) {
    return ContentInteractionStateEventDetailsCaseFromJSONTyped(json, false);
}
export function ContentInteractionStateEventDetailsCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ContentInteractionStateEventDetailsCaseToJSON(value) {
    return value;
}
