/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const TicketPermitType = {
    DOWNLOADABLE_FILE: 'DOWNLOADABLE_FILE',
    GIFT_CODE: 'GIFT_CODE',
    EXTERNAL_LINK: 'EXTERNAL_LINK',
    RECOMMENDED_LEARNING_CONTENT: 'RECOMMENDED_LEARNING_CONTENT',
    CURRICULUM_LEARNING_CONTENT: 'CURRICULUM_LEARNING_CONTENT',
    ALC_BOOKS_LEARNING_CONTENT: 'ALC_BOOKS_LEARNING_CONTENT'
};
export function TicketPermitTypeFromJSON(json) {
    return TicketPermitTypeFromJSONTyped(json, false);
}
export function TicketPermitTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TicketPermitTypeToJSON(value) {
    return value;
}
