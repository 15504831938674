import {ListVisibleLearningDomainsResponseLearningDomain} from '@santa-web/gen/services';

// 학습 시간 단축 등에 악용될까봐 일단 한눈에 알아볼 수 없는 class명으로 했습니다.
export const SKIP_AUDIO_CLASS_NAME = 'c2tpcC1hdWRpbw';

export const TOEFL_DOMAIN_INFO: ListVisibleLearningDomainsResponseLearningDomain = {
  key: 'TOEFL',
  id: 999,
  availability: true,
  description: '클라이언트측에서 임시 설정된 토플 도메인입니다',
  displayName: '임시 토플',
  imageSet: {
    imageUrlX1: 'https://picsum.photos/600/400',
    imageUrlX2: 'https://picsum.photos/600/400',
    imageUrlX3: 'https://picsum.photos/600/400',
    imageX1Id: 0,
    imageX2Id: 0,
    imageX3Id: 0,
  },
  isInFreePromotion: false,
};
