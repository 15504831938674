/* tslint:disable */
/* eslint-disable */
/**
 * Client에서 선택학습 콘텐츠 타입 별로 로직을 분기하기 위해 제공하는 Enum 값.
 * (See: [Slack](https://riiid.slack.com/archives/C04N92HF04R/p1729487689342189))
 *
 * - **ALC_BOOKS**: ALC 서적 콘텐츠 (see: [SPT-389](https://riiid-pioneer.atlassian.net/browse/SPT-389))
 * @export
 */
export const SelfLearningContentTypeAsEnum = {
    QUESTION: 'QUESTION',
    LESSON: 'LESSON',
    VOCABULARY: 'VOCABULARY',
    MOCK_EXAM: 'MOCK_EXAM',
    ALC_BOOKS: 'ALC_BOOKS'
};
export function SelfLearningContentTypeAsEnumFromJSON(json) {
    return SelfLearningContentTypeAsEnumFromJSONTyped(json, false);
}
export function SelfLearningContentTypeAsEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningContentTypeAsEnumToJSON(value) {
    return value;
}
