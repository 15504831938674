/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ElementInteractionStateV2DetailsCase = {
    QUESTION: 'QUESTION'
};
export function ElementInteractionStateV2DetailsCaseFromJSON(json) {
    return ElementInteractionStateV2DetailsCaseFromJSONTyped(json, false);
}
export function ElementInteractionStateV2DetailsCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ElementInteractionStateV2DetailsCaseToJSON(value) {
    return value;
}
