import React from 'react';
import * as Sentry from '@sentry/nextjs';

import {ErrorBody} from '@santa-web/gen/services';

export class AudioError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AudioError';
  }
}

export const handleAudioError: React.ReactEventHandler<HTMLAudioElement> = e => {
  const originalError = e.currentTarget.error;
  if (originalError) {
    const error = new AudioError(`${e.type}: ${originalError.message} (${originalError.code})`);
    Sentry.captureException(error);
  }
};

export class RecorderError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RecorderError';
  }
}

export const handleRecorderError = (e: RecorderError) => {
  Sentry.captureException(e);
};

export class SantaFetchError extends Error {
  constructor(e: Error) {
    super(e.message);
    this.stack = e.stack;
    this.name = 'SantaFetchError';
  }
}

const RIIID_HEADER_TRACE_ID_KEY = 'riiid-ddtid' as const;

export interface SantaResponseErrorConstructorParams {
  santaErrorCode?: string;
  santaErrorMessage?: string;
  traceId?: string;
  message: string;
  response: Response;
  request: RequestInit;
}

export class SantaResponseError extends Error {
  public santaErrorCode?: string;
  public santaErrorMessage?: string;
  public traceId?: string;
  public response: Response;
  public request: RequestInit;

  constructor({
    santaErrorCode,
    santaErrorMessage,
    message,
    request,
    response,
    traceId,
  }: SantaResponseErrorConstructorParams) {
    super(message);
    this.response = response;
    this.request = request;
    this.traceId = traceId;
    this.santaErrorCode = santaErrorCode;
    this.santaErrorMessage = santaErrorMessage;
    this.name = 'SantaResponseError';
  }

  static async fromResponse(response: Response, request: RequestInit) {
    const traceId = response.headers.get(RIIID_HEADER_TRACE_ID_KEY) ?? undefined;
    try {
      const errorBody = await response.json();
      if (!errorBody.code || !errorBody.message) {
        throw new Error();
      }
      const {code, message} = errorBody as ErrorBody;
      return new SantaResponseError({
        santaErrorCode: code,
        santaErrorMessage: message,
        traceId,
        message,
        response,
        request,
      });
    } catch (innerError) {
      return new SantaResponseError({
        message: `${response.status} ${response.statusText}`,
        traceId,
        response,
        request,
      });
    }
  }
}

/**
 * @description 산타가 초기 코어 상태 초기화에 실패했을 때 던지는 에러. 초기 코어 상태 초기화에 대해서는 [문서](https://www.notion.so/riiid/Santa-web-application-b423b02415364442b9b5387213a13634?pvs=4)참조.
 */
export class SantaBootFailureError extends Error {
  cause?: unknown;
  constructor(message: string, cause?: unknown) {
    super(message);
    this.name = 'SantaBootFailureError';
    this.cause = cause;
  }
}

export class ToeflNotImplementedError extends Error {
  constructor() {
    super();
    this.name = 'ToeflNotImplementedError';
  }
}

export class TimeoutError extends Error {
  public failedHistory: Error[];

  constructor(message: string, failureHistory?: Error[]) {
    super(message);
    this.name = 'TimeoutError';
    this.failedHistory = failureHistory ?? [];
  }
}
export const handleTimeoutError = (e: TimeoutError) => {
  Sentry.captureException(e, {
    extra: {
      failedHistory: e.failedHistory,
    },
  });
};
