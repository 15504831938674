/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const LearningCellExtraInfoOneOfCase = {
    DIAGNOSIS: 'DIAGNOSIS',
    COURSE_LEARNING: 'COURSE_LEARNING',
    SELF_LEARNING: 'SELF_LEARNING',
    MY_NOTE: 'MY_NOTE',
    TODAYS_LEARNING: 'TODAYS_LEARNING'
};
export function LearningCellExtraInfoOneOfCaseFromJSON(json) {
    return LearningCellExtraInfoOneOfCaseFromJSONTyped(json, false);
}
export function LearningCellExtraInfoOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningCellExtraInfoOneOfCaseToJSON(value) {
    return value;
}
