import { ContentInteractionStateWithContentV2FromJSON, ContentInteractionStateWithContentV2ToJSON, } from './ContentInteractionStateWithContentV2';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
/**
 * Check if a given object implements the ListContentInteractionStatesResponseV2 interface.
 */
export function instanceOfListContentInteractionStatesResponseV2(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStates" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListContentInteractionStatesResponseV2FromJSON(json) {
    return ListContentInteractionStatesResponseV2FromJSONTyped(json, false);
}
export function ListContentInteractionStatesResponseV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStates': (json['contentInteractionStates'].map(ContentInteractionStateWithContentV2FromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListContentInteractionStatesResponseV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStates': (value.contentInteractionStates.map(ContentInteractionStateWithContentV2ToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
