import {
  Configuration as ContentLearningServiceConfiguration,
  ContentInteractionStateV1Api,
  DiagnosisReportV1Api,
  DiagnosisV1Api,
  LearningCellV1Api,
  LearningCycleV1Api,
  LearningCycleReportV1Api,
  ChatRoomV1Api,
  ChatMessageV1Api,
  SkillAnalyticsV1Api,
  ContentStatisticsV1Api,
  LearningCellReportV1Api,
  UnderlineV1Api,
  LearningStatisticsV1Api,
  SelfLearningV1Api,
  LearningGoalV1Api,
  LearningHistoryV1Api,
  VocabularyLearningHistoryV1Api,
  ToeicVirtualExamV1Api,
  MyNoteQuizV1Api,
  CourseLearningV1Api,
  ContentResourceV1Api,
  HighlightsPreviewV1Api,
  HighlightV1Api,
  CourseReportV1Api,
  FeedbackV1Api,
} from '@santa-web/gen/open-api/content-learning-service';
import {
  Configuration as ContentLearningToeflConfiguration,
  LearningCellV2Api,
  ContentInteractionStateV2Api as ToeflContentInteractionStateV2Api,
  ContentV2Api,
  ElementInteractionStateReportV1Api,
  MockExamAV1Api,
} from '@santa-web/gen/open-api/content-learning-toefl';
import {
  Configuration as ToeicSpeakingConfiguration,
  ExamV1Api,
  ExamineeV1Api,
  ReplyV1Api,
  ResourceV1Api as ToeicSpeakingResourceV1Api,
  QuestionSetV1Api,
  TicketV1Api,
  CheckoutV1Api,
  TicketOrderV1Api,
  TicketProductV1Api,
  TicketProductBoardV1Api,
  CouponV1Api as ToeicSpeakingCouponV1Api,
} from '@santa-web/gen/open-api/toeic-speaking';
import {
  ServiceConfiguration,
  ConfigurationParameters,
  LearningDomainV1Api,
  CouponV1Api,
  OfferV1Api,
  ProfileV1Api,
  ReferralV1Api,
  StripeCheckoutV1Api,
  StripeSubscriptionV1Api,
  StripeProductBoardV1Api,
  StripeProductV1Api,
  KeyValueStoreV1Api,
  PointRewardV2Api,
  MarketingV1Api,
  SystemConfigV1Api,
  ExamScoreV1Api,
  ExamDateV1Api,
  ExamScoreConfigV1Api,
  OrderV1Api,
  PermitUserV2PassApi,
  PermitUserV2TicketApi,
  AuthConfiguration,
  AuthV1Api,
  UserConfigV1Api,
  UserV1Api,
  BackofficeConfiguration,
} from '@santa-web/gen/services';

interface ToeflOpenAPIServices {
  ToeflLearningCellService: LearningCellV2Api;
  ToeflContentInteractionStateService: ToeflContentInteractionStateV2Api;
  ToeflContentService: ContentV2Api;
  ToeflMockExamAService: MockExamAV1Api;
  ElementInteractionStateReportService: ElementInteractionStateReportV1Api;
}

export interface OpenAPIServices extends ToeflOpenAPIServices {
  AuthService: AuthV1Api;
  UserService: UserV1Api;
  UserConfigService: UserConfigV1Api;
  ReferralService: ReferralV1Api;
  ProfileService: ProfileV1Api;
  LearningDomainService: LearningDomainV1Api;
  LearningCellService: LearningCellV1Api;
  LearningCellReportService: LearningCellReportV1Api;
  LearningCycleService: LearningCycleV1Api;
  LearningGoalService: LearningGoalV1Api;
  SelfLearningService: SelfLearningV1Api;
  LearningStatisticsService: LearningStatisticsV1Api;
  ContentInteractionStateService: ContentInteractionStateV1Api;
  DiagnosisService: DiagnosisV1Api;
  StripeCheckoutService: StripeCheckoutV1Api;
  StripeSubscriptionService: StripeSubscriptionV1Api;
  StripeProductBoardService: StripeProductBoardV1Api;
  StripeProductService: StripeProductV1Api;
  UnderlineService: UnderlineV1Api;
  DiagnosisReportService: DiagnosisReportV1Api;
  LearningCycleReportService: LearningCycleReportV1Api;
  ChatRoomService: ChatRoomV1Api;
  ChatMessageService: ChatMessageV1Api;
  SkillAnalyticsService: SkillAnalyticsV1Api;
  ContentStatisticsService: ContentStatisticsV1Api;
  KeyValueStoreService: KeyValueStoreV1Api;
  PassService: PermitUserV2PassApi;
  TicketService: PermitUserV2TicketApi;
  LearningHistoryService: LearningHistoryV1Api;
  VocabularyLearningHistoryService: VocabularyLearningHistoryV1Api;
  ToeicVirtualExamService: ToeicVirtualExamV1Api;
  MyNoteQuizService: MyNoteQuizV1Api;
  PointRewardService: PointRewardV2Api;
  CourseLearningService: CourseLearningV1Api;
  ContentResourceService: ContentResourceV1Api;
  HighlightsPreviewService: HighlightsPreviewV1Api;
  HighlightService: HighlightV1Api;
  CouponService: CouponV1Api;
  MarketingService: MarketingV1Api;
  OfferService: OfferV1Api;
  SystemConfigService: SystemConfigV1Api;
  ExamScoreService: ExamScoreV1Api;
  ExamDateService: ExamDateV1Api;
  ExamScoreConfigService: ExamScoreConfigV1Api;
  OrderService: OrderV1Api;
  CourseReportService: CourseReportV1Api;
  FeedbackService: FeedbackV1Api;
}

export function createOpenAPIServices(configuration: ConfigurationParameters): OpenAPIServices {
  const authConfiguration = new AuthConfiguration(configuration);
  const serviceConfiguration = new ServiceConfiguration(configuration);
  const contentLearningConfiguration = new ContentLearningServiceConfiguration(configuration);
  const backofficeConfiguration = new BackofficeConfiguration(configuration);
  const toeflConfiguration = new ContentLearningToeflConfiguration(configuration);
  return {
    ToeflLearningCellService: new LearningCellV2Api(toeflConfiguration),
    ToeflContentInteractionStateService: new ToeflContentInteractionStateV2Api(toeflConfiguration),
    ToeflContentService: new ContentV2Api(toeflConfiguration),
    ToeflMockExamAService: new MockExamAV1Api(toeflConfiguration),
    AuthService: new AuthV1Api(authConfiguration),
    UserService: new UserV1Api(authConfiguration),
    UserConfigService: new UserConfigV1Api(authConfiguration),
    ReferralService: new ReferralV1Api(serviceConfiguration),
    ProfileService: new ProfileV1Api(serviceConfiguration),
    LearningDomainService: new LearningDomainV1Api(backofficeConfiguration),
    LearningCellService: new LearningCellV1Api(contentLearningConfiguration),
    LearningCellReportService: new LearningCellReportV1Api(contentLearningConfiguration),
    LearningCycleService: new LearningCycleV1Api(contentLearningConfiguration),
    LearningGoalService: new LearningGoalV1Api(contentLearningConfiguration),
    SelfLearningService: new SelfLearningV1Api(contentLearningConfiguration),
    LearningStatisticsService: new LearningStatisticsV1Api(contentLearningConfiguration),
    ContentInteractionStateService: new ContentInteractionStateV1Api(contentLearningConfiguration),
    DiagnosisService: new DiagnosisV1Api(contentLearningConfiguration),
    StripeCheckoutService: new StripeCheckoutV1Api(serviceConfiguration),
    StripeSubscriptionService: new StripeSubscriptionV1Api(serviceConfiguration),
    StripeProductBoardService: new StripeProductBoardV1Api(serviceConfiguration),
    StripeProductService: new StripeProductV1Api(serviceConfiguration),
    UnderlineService: new UnderlineV1Api(contentLearningConfiguration),
    DiagnosisReportService: new DiagnosisReportV1Api(contentLearningConfiguration),
    LearningCycleReportService: new LearningCycleReportV1Api(contentLearningConfiguration),
    ChatRoomService: new ChatRoomV1Api(contentLearningConfiguration),
    ChatMessageService: new ChatMessageV1Api(contentLearningConfiguration),
    SkillAnalyticsService: new SkillAnalyticsV1Api(contentLearningConfiguration),
    ContentStatisticsService: new ContentStatisticsV1Api(contentLearningConfiguration),
    KeyValueStoreService: new KeyValueStoreV1Api(serviceConfiguration),
    PassService: new PermitUserV2PassApi(backofficeConfiguration),
    TicketService: new PermitUserV2TicketApi(backofficeConfiguration),
    VocabularyLearningHistoryService: new VocabularyLearningHistoryV1Api(contentLearningConfiguration),
    ToeicVirtualExamService: new ToeicVirtualExamV1Api(contentLearningConfiguration),
    LearningHistoryService: new LearningHistoryV1Api(contentLearningConfiguration),
    MyNoteQuizService: new MyNoteQuizV1Api(contentLearningConfiguration),
    PointRewardService: new PointRewardV2Api(serviceConfiguration),
    CourseLearningService: new CourseLearningV1Api(contentLearningConfiguration),
    ContentResourceService: new ContentResourceV1Api(contentLearningConfiguration),
    HighlightsPreviewService: new HighlightsPreviewV1Api(contentLearningConfiguration),
    HighlightService: new HighlightV1Api(contentLearningConfiguration),
    CouponService: new CouponV1Api(backofficeConfiguration),
    MarketingService: new MarketingV1Api(backofficeConfiguration),
    OfferService: new OfferV1Api(backofficeConfiguration),
    SystemConfigService: new SystemConfigV1Api(backofficeConfiguration),
    ExamScoreService: new ExamScoreV1Api(serviceConfiguration),
    ExamDateService: new ExamDateV1Api(serviceConfiguration),
    ExamScoreConfigService: new ExamScoreConfigV1Api(serviceConfiguration),
    OrderService: new OrderV1Api(backofficeConfiguration),
    CourseReportService: new CourseReportV1Api(contentLearningConfiguration),
    FeedbackService: new FeedbackV1Api(contentLearningConfiguration),
    ElementInteractionStateReportService: new ElementInteractionStateReportV1Api(toeflConfiguration),
  };
}

export interface ToeicSpeakingOpenapiServices {
  ExamineeService: ExamineeV1Api;
  ExamService: ExamV1Api;
  ReplyService: ReplyV1Api;
  ResourceService: ToeicSpeakingResourceV1Api;
  QuestionSetService: QuestionSetV1Api;
  TicketService: TicketV1Api;
  CheckoutService: CheckoutV1Api;
  CouponService: ToeicSpeakingCouponV1Api;
  TicketOrderService: TicketOrderV1Api;
  TicketProductService: TicketProductV1Api;
  TicketProductBoardService: TicketProductBoardV1Api;
}

export function createToeicSpeakingOpenAPIServices(
  configuration: ToeicSpeakingConfiguration
): ToeicSpeakingOpenapiServices {
  return {
    ExamineeService: new ExamineeV1Api(configuration),
    ReplyService: new ReplyV1Api(configuration),
    ExamService: new ExamV1Api(configuration),
    ResourceService: new ToeicSpeakingResourceV1Api(configuration),
    QuestionSetService: new QuestionSetV1Api(configuration),
    TicketService: new TicketV1Api(configuration),
    CheckoutService: new CheckoutV1Api(configuration),
    CouponService: new ToeicSpeakingCouponV1Api(configuration),
    TicketOrderService: new TicketOrderV1Api(configuration),
    TicketProductService: new TicketProductV1Api(configuration),
    TicketProductBoardService: new TicketProductBoardV1Api(configuration),
  };
}
