import { QuestionStateV2AnswerStateFromJSON, QuestionStateV2AnswerStateToJSON, } from './QuestionStateV2AnswerState';
/**
 * Check if a given object implements the QuestionStateV2Objective interface.
 */
export function instanceOfQuestionStateV2Objective(value) {
    let isInstance = true;
    isInstance = isInstance && "answerState" in value;
    isInstance = isInstance && "correctAnswer" in value;
    isInstance = isInstance && "eliminatedAnswers" in value;
    isInstance = isInstance && "maxAnswerCount" in value;
    isInstance = isInstance && "minAnswerCount" in value;
    isInstance = isInstance && "userAnswer" in value;
    return isInstance;
}
export function QuestionStateV2ObjectiveFromJSON(json) {
    return QuestionStateV2ObjectiveFromJSONTyped(json, false);
}
export function QuestionStateV2ObjectiveFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answerState': QuestionStateV2AnswerStateFromJSON(json['answerState']),
        'correctAnswer': json['correctAnswer'],
        'eliminatedAnswers': json['eliminatedAnswers'],
        'maxAnswerCount': json['maxAnswerCount'],
        'minAnswerCount': json['minAnswerCount'],
        'userAnswer': json['userAnswer'],
    };
}
export function QuestionStateV2ObjectiveToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answerState': QuestionStateV2AnswerStateToJSON(value.answerState),
        'correctAnswer': value.correctAnswer,
        'eliminatedAnswers': value.eliminatedAnswers,
        'maxAnswerCount': value.maxAnswerCount,
        'minAnswerCount': value.minAnswerCount,
        'userAnswer': value.userAnswer,
    };
}
