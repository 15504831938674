/**
 * Check if a given object implements the GetElementInteractionStateSpeakingSttResponse interface.
 */
export function instanceOfGetElementInteractionStateSpeakingSttResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "speakingStt" in value;
    return isInstance;
}
export function GetElementInteractionStateSpeakingSttResponseFromJSON(json) {
    return GetElementInteractionStateSpeakingSttResponseFromJSONTyped(json, false);
}
export function GetElementInteractionStateSpeakingSttResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'speakingStt': json['speakingStt'],
    };
}
export function GetElementInteractionStateSpeakingSttResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'speakingStt': value.speakingStt,
    };
}
