/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningContentTypeAsEnumFromJSON, SelfLearningContentTypeAsEnumToJSON, } from './SelfLearningContentTypeAsEnum';
/**
 * Check if a given object implements the SelfLearningContentType interface.
 */
export function instanceOfSelfLearningContentType(value) {
    let isInstance = true;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
export function SelfLearningContentTypeFromJSON(json) {
    return SelfLearningContentTypeFromJSONTyped(json, false);
}
export function SelfLearningContentTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayName': json['displayName'],
        'enumValue': !exists(json, 'enumValue') ? undefined : SelfLearningContentTypeAsEnumFromJSON(json['enumValue']),
        'id': json['id'],
    };
}
export function SelfLearningContentTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayName': value.displayName,
        'enumValue': SelfLearningContentTypeAsEnumToJSON(value.enumValue),
        'id': value.id,
    };
}
