/**
 * Check if a given object implements the AlcBooksLearningContentPermitResource interface.
 */
export function instanceOfAlcBooksLearningContentPermitResource(value) {
    let isInstance = true;
    isInstance = isInstance && "selfLearningUnitGroupId" in value;
    return isInstance;
}
export function AlcBooksLearningContentPermitResourceFromJSON(json) {
    return AlcBooksLearningContentPermitResourceFromJSONTyped(json, false);
}
export function AlcBooksLearningContentPermitResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selfLearningUnitGroupId': json['selfLearningUnitGroupId'],
    };
}
export function AlcBooksLearningContentPermitResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selfLearningUnitGroupId': value.selfLearningUnitGroupId,
    };
}
