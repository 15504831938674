const isDevelopment = process.env.NODE_ENV === 'development';
const isBrowser = typeof window !== 'undefined';
const isMockingEnabled = isDevelopment && isBrowser;

const initMsw = async ({isEnabled}: {isEnabled: boolean}) => {
  if (!isEnabled || !isMockingEnabled) return;

  const {setupWorker} = await import('msw/browser');
  const {handlers} = await import('./handlers/index');
  const mswWorker = setupWorker(...handlers);

  mswWorker.start({onUnhandledRequest: 'bypass'});

  return () => {
    mswWorker.stop();
  };
};

export default initMsw;
