/**
 * Check if a given object implements the QuestionStateV2Speaking interface.
 */
export function instanceOfQuestionStateV2Speaking(value) {
    let isInstance = true;
    isInstance = isInstance && "downloadUrl" in value;
    isInstance = isInstance && "submitted" in value;
    isInstance = isInstance && "uploadUrl" in value;
    return isInstance;
}
export function QuestionStateV2SpeakingFromJSON(json) {
    return QuestionStateV2SpeakingFromJSONTyped(json, false);
}
export function QuestionStateV2SpeakingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'downloadUrl': json['downloadUrl'],
        'submitted': json['submitted'],
        'uploadUrl': json['uploadUrl'],
    };
}
export function QuestionStateV2SpeakingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'downloadUrl': value.downloadUrl,
        'submitted': value.submitted,
        'uploadUrl': value.uploadUrl,
    };
}
