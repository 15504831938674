/* tslint:disable */
/* eslint-disable */
/**
 * - **APP_STORE**: 앱스토어
 * - **PLAY_STORE**: 구글 플레이스토어
 * - **IAMPORT_KCP**: 아임포트 KCP
 * - **IAMPORT_NAVERPAY**: 아임포트 네이버페이
 * - **IAMPORT_TOSSPAYMENTS_CARD**: 아임포트 토스페이먼츠 (카드)
 * - **IAMPORT_TOSSPAYMENTS_TRANS**: 아임포트 토스페이먼츠 (계좌이체)
 * - **STRIPE**: 스트라이프
 * @export
 */
export const PaymentProvider = {
    APP_STORE: 'APP_STORE',
    PLAY_STORE: 'PLAY_STORE',
    IAMPORT_KCP: 'IAMPORT_KCP',
    IAMPORT_NAVERPAY: 'IAMPORT_NAVERPAY',
    IAMPORT_TOSSPAYMENTS_CARD: 'IAMPORT_TOSSPAYMENTS_CARD',
    IAMPORT_TOSSPAYMENTS_TRANS: 'IAMPORT_TOSSPAYMENTS_TRANS',
    STRIPE: 'STRIPE'
};
export function PaymentProviderFromJSON(json) {
    return PaymentProviderFromJSONTyped(json, false);
}
export function PaymentProviderFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PaymentProviderToJSON(value) {
    return value;
}
