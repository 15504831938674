/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElementInteractionStateV2DetailsCaseFromJSON, ElementInteractionStateV2DetailsCaseToJSON, } from './ElementInteractionStateV2DetailsCase';
import { QuestionStateV2FromJSON, QuestionStateV2ToJSON, } from './QuestionStateV2';
/**
 * Check if a given object implements the ElementInteractionStateV2Details interface.
 */
export function instanceOfElementInteractionStateV2Details(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function ElementInteractionStateV2DetailsFromJSON(json) {
    return ElementInteractionStateV2DetailsFromJSONTyped(json, false);
}
export function ElementInteractionStateV2DetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oneOfCase': ElementInteractionStateV2DetailsCaseFromJSON(json['oneOfCase']),
        'question': !exists(json, 'question') ? undefined : QuestionStateV2FromJSON(json['question']),
    };
}
export function ElementInteractionStateV2DetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oneOfCase': ElementInteractionStateV2DetailsCaseToJSON(value.oneOfCase),
        'question': QuestionStateV2ToJSON(value.question),
    };
}
