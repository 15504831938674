/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCellReportExtraInfoCourseLearningFromJSON, LearningCellReportExtraInfoCourseLearningToJSON, } from './LearningCellReportExtraInfoCourseLearning';
import { LearningCellReportExtraInfoDiagnosisFromJSON, LearningCellReportExtraInfoDiagnosisToJSON, } from './LearningCellReportExtraInfoDiagnosis';
import { LearningCellReportExtraInfoOneOfCaseFromJSON, LearningCellReportExtraInfoOneOfCaseToJSON, } from './LearningCellReportExtraInfoOneOfCase';
import { LearningCellReportExtraInfoSelfLearningFromJSON, LearningCellReportExtraInfoSelfLearningToJSON, } from './LearningCellReportExtraInfoSelfLearning';
/**
 * Check if a given object implements the LearningCellReportOneOfExtraInfo interface.
 */
export function instanceOfLearningCellReportOneOfExtraInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function LearningCellReportOneOfExtraInfoFromJSON(json) {
    return LearningCellReportOneOfExtraInfoFromJSONTyped(json, false);
}
export function LearningCellReportOneOfExtraInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseLearning': !exists(json, 'courseLearning') ? undefined : LearningCellReportExtraInfoCourseLearningFromJSON(json['courseLearning']),
        'diagnosis': !exists(json, 'diagnosis') ? undefined : LearningCellReportExtraInfoDiagnosisFromJSON(json['diagnosis']),
        'myNote': !exists(json, 'myNote') ? undefined : json['myNote'],
        'oneOfCase': LearningCellReportExtraInfoOneOfCaseFromJSON(json['oneOfCase']),
        'selfLearning': !exists(json, 'selfLearning') ? undefined : LearningCellReportExtraInfoSelfLearningFromJSON(json['selfLearning']),
    };
}
export function LearningCellReportOneOfExtraInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseLearning': LearningCellReportExtraInfoCourseLearningToJSON(value.courseLearning),
        'diagnosis': LearningCellReportExtraInfoDiagnosisToJSON(value.diagnosis),
        'myNote': value.myNote,
        'oneOfCase': LearningCellReportExtraInfoOneOfCaseToJSON(value.oneOfCase),
        'selfLearning': LearningCellReportExtraInfoSelfLearningToJSON(value.selfLearning),
    };
}
