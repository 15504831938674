// 클릭 이벤트 센트리 로깅

import {useEffect} from 'react';
import {addClickBreadcrumb} from '@app/utils/trace';

export const useClickBreadcrumb = () => {
  useEffect(() => {
    const callback = (e: Event) => {
      const clickedElement = e.target as HTMLElement;
      const closestClickableElement: HTMLElement | null = clickedElement.closest('a, button, [role="button"]');
      if (!closestClickableElement) return;

      const label = closestClickableElement.innerText;
      if (label) {
        return addClickBreadcrumb(label);
      }

      const icon =
        clickedElement.tagName === 'path'
          ? clickedElement.closest('svg')
          : clickedElement.tagName === 'svg'
          ? clickedElement
          : clickedElement.querySelector('svg');

      if (icon && icon.dataset.iconName) {
        return addClickBreadcrumb(`IconButton : ${icon.dataset.iconName}`);
      }

      return addClickBreadcrumb('UNKNOWN');
    };

    window.addEventListener('click', callback);
    return () => {
      window.removeEventListener('click', callback);
    };
  }, []);
};
