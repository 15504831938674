/* tslint:disable */
/* eslint-disable */
/**
 * 사용자가 제출한 답안 상태
 *
 * - **CORRECT**: 사용자의 답안이 기재되어 있고, 해당 답안이 정답임.
 * - **INCORRECT**: 기재되어 있으나 정답이 아니고 "모름"을 선택하지 않음.
 * - **NOT_ANSWERED**: 사용자의 답안이 기재되어 있지 않음.
 * - **DOES_NOT_KNOW**: [Objective] 문제에 대해 사용자의 답안이 "모름"으로 선택되어 있음.
 * @export
 */
export const QuestionStateV2AnswerState = {
    CORRECT: 'CORRECT',
    INCORRECT: 'INCORRECT',
    NOT_ANSWERED: 'NOT_ANSWERED',
    DOES_NOT_KNOW: 'DOES_NOT_KNOW'
};
export function QuestionStateV2AnswerStateFromJSON(json) {
    return QuestionStateV2AnswerStateFromJSONTyped(json, false);
}
export function QuestionStateV2AnswerStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function QuestionStateV2AnswerStateToJSON(value) {
    return value;
}
