import React from 'react';
import {useAsync, useMount} from 'react-use';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {useGetMobileAppInfoQuery} from '@app/features/preferences';
import createVersionWrapper from '@app/new-structure/useCases/VersionChecker/VersionWrapper';
import {SantaNavigationPath} from '@app/types/navigation';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {getIsMobileOs, getUserAgent} from '@app/utils/user-agent';
import {useTypedRouter} from './useTypedRouter';

// https://riiid-pioneer.atlassian.net/browse/SPT-185
const brazeInAppMessageUnavailablePaths: SantaNavigationPath[] = [
  '/learning-cell',
  '/learning-cell/crm',
  '/learning-cell/report',
  '/feedback',
  '/content-interaction-state',
  '/content-interaction-states',
  '/course/learning-cell',
  '/cycle/report',
  '/diagnosis-report',
  '/virtual-exam',
  '/virtual-exam/report',
  '/virtual-exam/report/weakness',
  '/offer-group/board/detail',
  '/offer-group/offer/list',
  '/offer-group/order',
  '/offer-group/order/failure',
  '/offer-group/order/complete',
  '/offer-group/order/result',
];
const getIsBrazeInAppMessageUnavailablePath = (pathname: string) => {
  return brazeInAppMessageUnavailablePaths.some(path => pathname === path);
};

const mobileUnavailablePagePrefixes = ['/course', '/me', '/review', '/self-learning'];
const getIsMobileUnavailablePage = (pathname: string) => {
  return mobileUnavailablePagePrefixes.some(prefix => pathname.startsWith(prefix));
};

export const useRootRouteChange = () => {
  const router = useTypedRouter();
  const {data: mobileAppInfo} = useGetMobileAppInfoQuery();
  const isWebview = React.useMemo(() => isAppBridgeAvailable(), []);
  const [shouldBlockRenderingChildren, setShouldBlockRenderingChildren] = React.useState(false);

  const handleRouteChangeStart = (url: string) => {
    if (getIsMobileOs(getUserAgent()) && !isWebview && getIsMobileUnavailablePage(url)) {
      setShouldBlockRenderingChildren(true);
    }
  };

  const handleRouteChangeComplete = (url: string) => {
    if (!getIsMobileOs(getUserAgent()) || isWebview || !getIsMobileUnavailablePage(url)) {
      setShouldBlockRenderingChildren(false);
    }
  };

  useMount(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  });

  useAsync(async () => {
    if (shouldBlockRenderingChildren) {
      await router.replace('/app-download');
    }
  }, [shouldBlockRenderingChildren]);

  React.useEffect(() => {
    if (!isWebview || !mobileAppInfo || !mobileAppInfo.version) {
      return;
    }
    const version = createVersionWrapper(mobileAppInfo.version);
    if (!version.gte(createVersionWrapper({major: 6, minor: 3, patch: 0}))) {
      return;
    }
    const pathname = router.pathname;
    const isBrazeInAppMessageUnavailablePath = getIsBrazeInAppMessageUnavailablePath(pathname);

    mobileService.setIsBrazeInAppMessageAvailable(!isBrazeInAppMessageUnavailablePath);
  }, [isWebview, mobileAppInfo, router.pathname]);

  return {shouldBlockRenderingChildren};
};
