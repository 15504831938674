import {addBreadcrumb as _addBreadcrumb} from '@sentry/nextjs';

export const addClickBreadcrumb = (label: string, extraData?: Record<string, any>) => {
  _addBreadcrumb({
    level: 'info',
    category: 'BUTTON CLICK',
    message: label,
    data: extraData,
  });
};
