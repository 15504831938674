/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { LearningCellResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningCellV2Api extends runtime.BaseAPI {
    /**
     * 학습 셀을 생성한다. (TOEFL 개발 전용, 추후 제거 예정)
     */
    async createLearningCellRaw(requestParameters, initOverrides) {
        if (requestParameters.isMockExam === null || requestParameters.isMockExam === undefined) {
            throw new runtime.RequiredError('isMockExam', 'Required parameter requestParameters.isMockExam was null or undefined when calling createLearningCell.');
        }
        if (requestParameters.sectionType === null || requestParameters.sectionType === undefined) {
            throw new runtime.RequiredError('sectionType', 'Required parameter requestParameters.sectionType was null or undefined when calling createLearningCell.');
        }
        const queryParameters = {};
        if (requestParameters.questionContentType !== undefined) {
            queryParameters['questionContentType'] = requestParameters.questionContentType;
        }
        if (requestParameters.isMockExam !== undefined) {
            queryParameters['isMockExam'] = requestParameters.isMockExam;
        }
        if (requestParameters.sectionType !== undefined) {
            queryParameters['sectionType'] = requestParameters.sectionType;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/learning-cells`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LearningCellResponseFromJSON(jsonValue));
    }
    /**
     * 학습 셀을 생성한다. (TOEFL 개발 전용, 추후 제거 예정)
     */
    async createLearningCell(requestParameters, initOverrides) {
        const response = await this.createLearningCellRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
