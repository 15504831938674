import React from 'react';
import {LottieAnimation, LottieAnimationProps} from '@santa-web/service-ui';

export type LoadingGrayLogoAnimationProps = Omit<LottieAnimationProps, 'src'>;

const LoadingGrayLogoAnimation = ({...props}: LoadingGrayLogoAnimationProps): JSX.Element => {
  return <LottieAnimation src={'/lottie/loading-lottie.json'} {...props} />;
};

export default React.memo(LoadingGrayLogoAnimation);
