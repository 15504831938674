/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { MockExamAPromotionInfoFromJSON, MockExamAPromotionInfoToJSON, } from './MockExamAPromotionInfo';
import { MockExamASelfLearningUnitInfoFromJSON, MockExamASelfLearningUnitInfoToJSON, } from './MockExamASelfLearningUnitInfo';
/**
 * Check if a given object implements the MockExamAInfo interface.
 */
export function instanceOfMockExamAInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCellExists" in value;
    isInstance = isInstance && "selfLearningUnitInfo" in value;
    return isInstance;
}
export function MockExamAInfoFromJSON(json) {
    return MockExamAInfoFromJSONTyped(json, false);
}
export function MockExamAInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCellExists': json['learningCellExists'],
        'promotionInfo': !exists(json, 'promotionInfo') ? undefined : MockExamAPromotionInfoFromJSON(json['promotionInfo']),
        'selfLearningUnitInfo': MockExamASelfLearningUnitInfoFromJSON(json['selfLearningUnitInfo']),
    };
}
export function MockExamAInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCellExists': value.learningCellExists,
        'promotionInfo': MockExamAPromotionInfoToJSON(value.promotionInfo),
        'selfLearningUnitInfo': MockExamASelfLearningUnitInfoToJSON(value.selfLearningUnitInfo),
    };
}
