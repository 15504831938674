/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningContentTypeAsEnumFromJSON, SelfLearningContentTypeAsEnumToJSON, } from './SelfLearningContentTypeAsEnum';
/**
 * Check if a given object implements the LearningCellReportExtraInfoSelfLearning interface.
 */
export function instanceOfLearningCellReportExtraInfoSelfLearning(value) {
    let isInstance = true;
    return isInstance;
}
export function LearningCellReportExtraInfoSelfLearningFromJSON(json) {
    return LearningCellReportExtraInfoSelfLearningFromJSONTyped(json, false);
}
export function LearningCellReportExtraInfoSelfLearningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': !exists(json, 'contentType') ? undefined : SelfLearningContentTypeAsEnumFromJSON(json['contentType']),
    };
}
export function LearningCellReportExtraInfoSelfLearningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': SelfLearningContentTypeAsEnumToJSON(value.contentType),
    };
}
