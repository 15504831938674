/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ContentResponseV2FromJSON, } from '../models';
/**
 *
 */
export class ContentV2Api extends runtime.BaseAPI {
    /**
     * 주어진 ID에 대한 `Content`를 조회한다.\\ response type은 [LearningHub 인터페이스](https://tech-learninghub.dev.riiid.exposed/swagger-ui/index.html#/Content%20APIs/getContent)를 참조한다.
     */
    async getContentRaw(requestParameters, initOverrides) {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId', 'Required parameter requestParameters.contentId was null or undefined when calling getContent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v2/content/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentResponseV2FromJSON(jsonValue));
    }
    /**
     * 주어진 ID에 대한 `Content`를 조회한다.\\ response type은 [LearningHub 인터페이스](https://tech-learninghub.dev.riiid.exposed/swagger-ui/index.html#/Content%20APIs/getContent)를 참조한다.
     */
    async getContent(requestParameters, initOverrides) {
        const response = await this.getContentRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
