/**
 * Check if a given object implements the ContentInteractionStateEventDetailsUpdateObjectiveAnswer interface.
 */
export function instanceOfContentInteractionStateEventDetailsUpdateObjectiveAnswer(value) {
    let isInstance = true;
    isInstance = isInstance && "elementInteractionStateId" in value;
    isInstance = isInstance && "eliminatedAnswers" in value;
    isInstance = isInstance && "userAnswer" in value;
    return isInstance;
}
export function ContentInteractionStateEventDetailsUpdateObjectiveAnswerFromJSON(json) {
    return ContentInteractionStateEventDetailsUpdateObjectiveAnswerFromJSONTyped(json, false);
}
export function ContentInteractionStateEventDetailsUpdateObjectiveAnswerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elementInteractionStateId': json['elementInteractionStateId'],
        'eliminatedAnswers': json['eliminatedAnswers'],
        'userAnswer': json['userAnswer'],
    };
}
export function ContentInteractionStateEventDetailsUpdateObjectiveAnswerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elementInteractionStateId': value.elementInteractionStateId,
        'eliminatedAnswers': value.eliminatedAnswers,
        'userAnswer': value.userAnswer,
    };
}
