/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetElementInteractionStateRaterCommentsResponseFromJSON, GetElementInteractionStateSpeakingSttResponseFromJSON, } from '../models';
/**
 *
 */
export class ElementInteractionStateReportV1Api extends runtime.BaseAPI {
    /**
     * 답안 제출이 완료된 말하기 및 쓰기 문제의 사용자 답안에 대한 평가자 코멘트를 조회한다.
     * 말하기 및 쓰기 문제의 사용자 답안에 대한 평가자 코멘트를 조회한다.
     */
    async getElementInteractionStateRaterCommentsRaw(requestParameters, initOverrides) {
        if (requestParameters.elementInteractionStateId === null || requestParameters.elementInteractionStateId === undefined) {
            throw new runtime.RequiredError('elementInteractionStateId', 'Required parameter requestParameters.elementInteractionStateId was null or undefined when calling getElementInteractionStateRaterComments.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/analytics/v1/element-interaction-state-reports/{elementInteractionStateId}/rater-comments`.replace(`{${"elementInteractionStateId"}}`, encodeURIComponent(String(requestParameters.elementInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetElementInteractionStateRaterCommentsResponseFromJSON(jsonValue));
    }
    /**
     * 답안 제출이 완료된 말하기 및 쓰기 문제의 사용자 답안에 대한 평가자 코멘트를 조회한다.
     * 말하기 및 쓰기 문제의 사용자 답안에 대한 평가자 코멘트를 조회한다.
     */
    async getElementInteractionStateRaterComments(requestParameters, initOverrides) {
        const response = await this.getElementInteractionStateRaterCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 답안 제출이 완료된 말하기 문제의 사용자 답안에 대한 STT 결과를 조회한다.
     * 말하기 문제의 사용자 답안에 대한 STT 결과를 조회한다.
     */
    async getElementInteractionStateSpeakingSttRaw(requestParameters, initOverrides) {
        if (requestParameters.elementInteractionStateId === null || requestParameters.elementInteractionStateId === undefined) {
            throw new runtime.RequiredError('elementInteractionStateId', 'Required parameter requestParameters.elementInteractionStateId was null or undefined when calling getElementInteractionStateSpeakingStt.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/analytics/v1/element-interaction-state-reports/{elementInteractionStateId}/speaking-stt`.replace(`{${"elementInteractionStateId"}}`, encodeURIComponent(String(requestParameters.elementInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetElementInteractionStateSpeakingSttResponseFromJSON(jsonValue));
    }
    /**
     * 답안 제출이 완료된 말하기 문제의 사용자 답안에 대한 STT 결과를 조회한다.
     * 말하기 문제의 사용자 답안에 대한 STT 결과를 조회한다.
     */
    async getElementInteractionStateSpeakingStt(requestParameters, initOverrides) {
        const response = await this.getElementInteractionStateSpeakingSttRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
