/**
 * Check if a given object implements the ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmitted interface.
 */
export function instanceOfContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmitted(value) {
    let isInstance = true;
    isInstance = isInstance && "elementInteractionStateId" in value;
    return isInstance;
}
export function ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedFromJSON(json) {
    return ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedFromJSONTyped(json, false);
}
export function ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elementInteractionStateId': json['elementInteractionStateId'],
    };
}
export function ContentInteractionStateEventDetailsMarkSpeakingQuestionAsSubmittedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elementInteractionStateId': value.elementInteractionStateId,
    };
}
