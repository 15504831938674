import { ContentInteractionStateEventDetailsFromJSON, ContentInteractionStateEventDetailsToJSON, } from './ContentInteractionStateEventDetails';
/**
 * Check if a given object implements the ContentInteractionStateEvent interface.
 */
export function instanceOfContentInteractionStateEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "eventAt" in value;
    return isInstance;
}
export function ContentInteractionStateEventFromJSON(json) {
    return ContentInteractionStateEventFromJSONTyped(json, false);
}
export function ContentInteractionStateEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'details': ContentInteractionStateEventDetailsFromJSON(json['details']),
        'eventAt': (new Date(json['eventAt'])),
    };
}
export function ContentInteractionStateEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'details': ContentInteractionStateEventDetailsToJSON(value.details),
        'eventAt': (value.eventAt.toISOString()),
    };
}
