/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ContentInteractionStateV2FromJSON, ContentInteractionStateV2ToJSON, } from './ContentInteractionStateV2';
import { OneOfContentSummaryFromJSON, OneOfContentSummaryToJSON, } from './OneOfContentSummary';
/**
 * Check if a given object implements the ContentInteractionStateWithContentV2 interface.
 */
export function instanceOfContentInteractionStateWithContentV2(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionState" in value;
    isInstance = isInstance && "contentSummary" in value;
    return isInstance;
}
export function ContentInteractionStateWithContentV2FromJSON(json) {
    return ContentInteractionStateWithContentV2FromJSONTyped(json, false);
}
export function ContentInteractionStateWithContentV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionState': ContentInteractionStateV2FromJSON(json['contentInteractionState']),
        'contentSummary': OneOfContentSummaryFromJSON(json['contentSummary']),
        'recommendedElapsedTime': !exists(json, 'recommendedElapsedTime') ? undefined : json['recommendedElapsedTime'],
    };
}
export function ContentInteractionStateWithContentV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionState': ContentInteractionStateV2ToJSON(value.contentInteractionState),
        'contentSummary': OneOfContentSummaryToJSON(value.contentSummary),
        'recommendedElapsedTime': value.recommendedElapsedTime,
    };
}
