/**
 * Check if a given object implements the MockExamAPromotionInfo interface.
 */
export function instanceOfMockExamAPromotionInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "discountPercent" in value;
    isInstance = isInstance && "validUntil" in value;
    return isInstance;
}
export function MockExamAPromotionInfoFromJSON(json) {
    return MockExamAPromotionInfoFromJSONTyped(json, false);
}
export function MockExamAPromotionInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discountPercent': json['discountPercent'],
        'validUntil': (new Date(json['validUntil'])),
    };
}
export function MockExamAPromotionInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discountPercent': value.discountPercent,
        'validUntil': (value.validUntil.toISOString()),
    };
}
