/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { MockExamAInfoFromJSON, } from '../models';
/**
 *
 */
export class MockExamAV1Api extends runtime.BaseAPI {
    /**
     * 사용자의 국가에 맞는 모의고사A 관련 정보를 조회한다. (see: [[SPT-657][KR] 고객은 이벤트 기간 내에 모의고사를 \'완료\'했을 때 n%할인 쿠폰을 지급 받을 수 있음.](https://riiid-pioneer.atlassian.net/browse/SPT-657))
     * 모의고사A 관련 정보를 조회한다.
     */
    async getMockExamAInfoRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/toefl/content-learning/v1/mock-exam-a`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MockExamAInfoFromJSON(jsonValue));
    }
    /**
     * 사용자의 국가에 맞는 모의고사A 관련 정보를 조회한다. (see: [[SPT-657][KR] 고객은 이벤트 기간 내에 모의고사를 \'완료\'했을 때 n%할인 쿠폰을 지급 받을 수 있음.](https://riiid-pioneer.atlassian.net/browse/SPT-657))
     * 모의고사A 관련 정보를 조회한다.
     */
    async getMockExamAInfo(requestParameters, initOverrides) {
        const response = await this.getMockExamAInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
