/* tslint:disable */
/* eslint-disable */
/**
 * - **EXTERNAL_LINK**: 외부 링크
 * - **GIFT_CODE**: 기프트 코드
 * - **DOWNLOADABLE_FILE**: 파일
 * - **RECOMMENDED_LEARNING_CONTENT**: 추천 학습
 * - **CURRICULUM_LEARNING_CONTENT**: 커리큘럼 추천학습
 * - **ALC_BOOKS_LEARNING_CONTENT**: ALC 서적 선택학습
 * @export
 */
export const PermitResourceCase = {
    EXTERNAL_LINK: 'EXTERNAL_LINK',
    GIFT_CODE: 'GIFT_CODE',
    DOWNLOADABLE_FILE: 'DOWNLOADABLE_FILE',
    RECOMMENDED_LEARNING_CONTENT: 'RECOMMENDED_LEARNING_CONTENT',
    CURRICULUM_LEARNING_CONTENT: 'CURRICULUM_LEARNING_CONTENT',
    ALC_BOOKS_LEARNING_CONTENT: 'ALC_BOOKS_LEARNING_CONTENT'
};
export function PermitResourceCaseFromJSON(json) {
    return PermitResourceCaseFromJSONTyped(json, false);
}
export function PermitResourceCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PermitResourceCaseToJSON(value) {
    return value;
}
