/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const QuestionStateV2DetailsCase = {
    OBJECTIVE: 'OBJECTIVE',
    SPEAKING: 'SPEAKING',
    WRITING: 'WRITING'
};
export function QuestionStateV2DetailsCaseFromJSON(json) {
    return QuestionStateV2DetailsCaseFromJSONTyped(json, false);
}
export function QuestionStateV2DetailsCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function QuestionStateV2DetailsCaseToJSON(value) {
    return value;
}
